import React, { Component } from "react";
import WeekView from "./WeekView/WeekView";
import FeedView from "./FeedView/FeedView";
import RiderView from "./RiderView/RiderView";
import Rider2RowsView from "./Rider2RowsView/Rider2RowsView";
import TimeSinceLastHA from "./TimeSinceLastHA/TimeSinceLastHA";
import StaticRiderView from "./RiderView/StaticRiderView";
import FieldAssignmentView from "../FieldAssignmentView/FieldAssignmentView";
import OtherTasksView from "../OtherTasksView/OtherTasksView";
import OtherTasksViewLight from "../OtherTasksViewLight/OtherTasksViewLight";
import FieldAndOtherTasks from "../FieldAndOtherTasks/FieldAndOtherTasks";
import WalkerAssignmentView from "../WalkerAssignmentView/WalkerAssignmentView";
import AllHorseDataWithPrice from "./AllHorseWithPriceView/AllHorseDataWithPrice";
import SalesData from "./SalesData/SalesData";
import RaiderAndOtherTasks from "./RaiderAndOtherTasks/RaiderAndOtherTasks";

class MainView extends Component {
  constructor(props) {
    super(props);
    this.filteredHorses = [];
    this.filteredTrainings = [];
    this.filteredGeneralTaskArr = [];
    this.fieldAreas = [];
  }

  applyFilters = () => {
    let horseArr = [
      ...window.DataStorage.myHorseArray,
      ...window.DataStorage.notMyHorseArray,
    ];
    let trainingArray = window.DataStorage.addTrainingArr;
    let stablesArray = window.DataStorage.boxesAndStables;

    // fiter by type
    const horseType = JSON.parse(
      window.GlobalData.getItem(window.GlobalData.SHOW_IN_TRAINING)
    );
    if (
      horseType == null ||
      horseType.length == 0 ||
      horseType[0] == "1" ||
      horseType[0] == "101"
    ) {
      //show all
    } else if (horseType[0] == "2") {
      //in training
      horseArr = horseArr.filter((h) => h.istraining == "1");
    } else if (horseType[0] == "3") {
      //own in training
      horseArr = horseArr.filter(
        (h) => h.istraining == "1" && h.userid == window.myid
      );
    } else {
      let horseTypeIds = horseType.map((i) => {
        let horseTypeArr = i.split(",");
        return horseTypeArr;
      });
      horseTypeIds = horseTypeIds.flat();
      horseArr = horseArr.filter(
        (h) => horseTypeIds.indexOf(h.HorseType) != -1
      );
    }

    // filter by stable
    const stableId = JSON.parse(
      window.GlobalData.getItem(window.GlobalData.SHOW_IN_STABLE)
    );
    if (stableId[stableId.length - 1] == "1") {
      //show all
    } else {
      stablesArray = stablesArray.filter((i) => {
        let stIndex = stableId.indexOf(i.IdStable);
        return i.IdStable == stableId[stIndex];
      });
      horseArr = horseArr.filter((h) => {
        let stArr =
          window.inUseStables == "Y"
            ? stablesArray.filter((t) => t.IDBox == h.BoxName)
            : stablesArray.filter((t) => t.IdStable == h.StableId);
        return stArr.length != 0;
      });
    }

    if (
      this.props.mode != window.TrainingManager.riderStatic &&
      this.props.mode != window.TrainingManager.riderStaticAndEquipment &&
      this.props.mode != window.TrainingManager.riderStaticLight &&
      this.props.mode != window.TrainingManager.riderStaticAndEquipmentLight
    ) {
      // filter by rider
      const riderId = JSON.parse(
        window.GlobalData.getItem(window.GlobalData.FILTER_RIDER)
      );
      if (riderId[riderId.length - 1] != "1") {
        trainingArray = trainingArray.filter((t) => {
          let rIndex = riderId.indexOf(t.trainer);
          return t.trainer == riderId[rIndex];
        });
        horseArr = horseArr.filter((h) => {
          let tArr = trainingArray.filter((t) => t.horseid == h.id);
          return tArr.length != 0;
        });
      }
    }

    // filter by label
    const labelId = JSON.parse(
      window.GlobalData.getItem(window.GlobalData.FILTER_LABEL)
    );
    if (labelId[labelId.length - 1] != "1") {
      horseArr = horseArr.filter((h) => {
        let horseLabelArr = JSON.parse(h.LabelIds);
        return horseLabelArr
          ? horseLabelArr.map((j) => labelId.indexOf(j) != -1).includes(true)
          : false;
      });
    }

    horseArr = horseArr.sort(function (h1, h2) {
      return h1.name.toLowerCase() > h2.name.toLowerCase() ? 1 : -1;
    });
    this.filteredHorses = horseArr;
    this.filteredTrainings = trainingArray;
  };

  render() {
    this.applyFilters();
    switch (this.props.mode) {
      case window.TrainingManager.otherTasks:
        if (window.DataStorage.addGeneralTaskArr)
          this.filteredGeneralTaskArr = window.DataStorage.addGeneralTaskArr;

        return (
          <OtherTasksView
            date={window.training.startDate}
            generalTaskArr={this.filteredGeneralTaskArr}
          />
        );
      case window.TrainingManager.otherTasksLight:
        if (window.DataStorage.addGeneralTaskArr)
          this.filteredGeneralTaskArr = window.DataStorage.addGeneralTaskArr;
        // .filter(
        //   (t) => t.Time == otherTasksselectedDate
        // );
        return (
          <OtherTasksViewLight
            date={window.training.startDate}
            generalTaskArr={this.filteredGeneralTaskArr}
          />
        );
      case window.TrainingManager.fieldAndOtherTasks:
        if (window.DataStorage.addGeneralTaskArr)
          this.filteredGeneralTaskArr = window.DataStorage.addGeneralTaskArr;

        this.fieldAreas = window.DataStorage.fieldAreas;
        let selectedDateFieldFieldAndOther =
          window.training.startDate.formatDesh();
        let filteredTrainingsFieldFieldAndOther = this.filteredTrainings.filter(
          (t) => t.starttime == selectedDateFieldFieldAndOther
        );

        return (
          <FieldAndOtherTasks
            fieldAreas={this.fieldAreas}
            filteredHorses={this.filteredHorses}
            date={window.training.startDate}
            generalTaskArr={this.filteredGeneralTaskArr}
            selectedDateField={selectedDateFieldFieldAndOther}
            filteredTrainingsField={filteredTrainingsFieldFieldAndOther}
          />
        );
      case window.TrainingManager.fieldAssignment:
        this.fieldAreas = window.DataStorage.fieldAreas;
        let selectedDateField = window.training.startDate.formatDesh();
        let filteredTrainingsField = this.filteredTrainings.filter(
          (t) => t.starttime == selectedDateField
        );
        return (
          <FieldAssignmentView
            fieldAreas={this.fieldAreas}
            filteredHorses={this.filteredHorses}
            filteredTrainings={filteredTrainingsField}
            date={window.training.startDate}
            generalTaskArr={this.filteredGeneralTaskArr}
          />
        );

      case window.TrainingManager.walkerAssignment:
        this.walkerAreas = window.DataStorage.walkerAreas;
        let selectedDateWalker = window.training.startDate.formatDesh();
        let filteredTrainingsWalker = this.filteredTrainings.filter(
          (t) => t.starttime == selectedDateWalker
        );
        return (
          <WalkerAssignmentView
            walkerAreas={this.walkerAreas}
            filteredHorses={this.filteredHorses}
            filteredTrainings={filteredTrainingsWalker}
            date={window.training.startDate}
          />
        );

      case window.TrainingManager.raiderAndOtherTasks:
        let selectedDateRaider = window.training.startDate.formatDesh();
        let filteredTrainingsRaider = this.filteredTrainings.filter(
          (t) => t.starttime == selectedDateRaider
        );
        this.filteredGeneralTaskArr = window.DataStorage.addGeneralTaskArr;

        return (
          <RaiderAndOtherTasks
            filteredHorses={this.filteredHorses}
            filteredTrainings={filteredTrainingsRaider}
            date={window.training.startDate}
            showOnlyOwnHorses={
              this.props.mode == window.Training.mode_own_horses
            }
            generalTaskArr={this.filteredGeneralTaskArr}
          />
        );
      case window.TrainingManager.salesData:
        return (
          <SalesData
            filteredHorses={this.filteredHorses}
            filteredTrainings={this.filteredTrainings}
          />
        );
      case window.TrainingManager.mode_table_2:
      case window.TrainingManager.mode_equipment:
        return (
          <WeekView
            filteredHorses={this.filteredHorses}
            filteredTrainings={this.filteredTrainings}
            showEquipment={this.props.mode == window.Training.mode_equipment}
          />
        );
      case window.TrainingManager.mode_rider_2:
      case window.TrainingManager.mode_own_horses: {
        let selectedDate = window.training.startDate.formatDesh();
        let filteredTrainings = this.filteredTrainings.filter(
          (t) => t.starttime == selectedDate
        );
        return (
          <RiderView
            filteredHorses={this.filteredHorses}
            filteredTrainings={filteredTrainings}
            date={window.training.startDate}
            showOnlyOwnHorses={
              this.props.mode == window.Training.mode_own_horses
            }
          />
        );
      }
      case window.TrainingManager.riderStatic:
      case window.TrainingManager.riderStaticAndEquipment:
      case window.TrainingManager.riderStaticLight:
      case window.TrainingManager.riderStaticAndEquipmentLight: {
        let selectedDate = window.training.startDate.formatDesh();
        let filteredTrainings = this.filteredTrainings.filter(
          (t) => t.starttime == selectedDate
        );
        if (window.DataStorage.addGeneralTaskArr)
          this.filteredGeneralTaskArr =
            window.DataStorage.addGeneralTaskArr.filter(
              (t) => t.Time == selectedDate
            );
        return (
          <StaticRiderView
            filteredHorses={this.filteredHorses}
            filteredTrainings={filteredTrainings}
            date={window.training.startDate}
            showEquipment={
              this.props.mode ==
                window.TrainingManager.riderStaticAndEquipment ||
              this.props.mode ==
                window.TrainingManager.riderStaticAndEquipmentLight
            }
            generalTaskArr={this.filteredGeneralTaskArr}
          />
        );
      }
      case window.TrainingManager.mode_feed:
        return <FeedView filteredHorses={this.filteredHorses} />;
      case window.TrainingManager.mode_rider2:
        let selectedDate = window.training.startDate.formatDesh();
        let filteredTrainings = this.filteredTrainings.filter(
          (t) => t.starttime == selectedDate
        );
        return (
          <Rider2RowsView
            filteredHorses={this.filteredHorses}
            filteredTrainings={filteredTrainings}
            date={window.training.startDate}
            showOnlyOwnHorses={
              this.props.mode == window.Training.mode_own_horses
            }
          />
        );
      case window.TrainingManager.timeSinceLastHA:
        return (
          <TimeSinceLastHA
            filteredHorses={this.filteredHorses}
            date={window.training.startDate.formatDesh()}
          />
        );
    }

    return <div></div>;
  }
}

export default MainView;
