import { Directory, Filesystem } from "@capacitor/filesystem";
import MultimediaMobileHelper from "./MultimediaMobileHelper";
import { Share } from "@capacitor/share";
import JSZip, { files } from "jszip";
import { FileChunkReader } from "capacitor-file-chunk-reader";
import { random } from "lodash";
import { Capacitor } from "@capacitor/core";
import { BlobServiceClient } from "@azure/storage-blob";
import { saveAs } from "file-saver";
import { VideoEditor } from "@whiteguru/capacitor-plugin-video-editor";
export default class MultimediaHelper {
  static DropboxToken =
    "HqmkA9nKm0wAAAAAAADLWpj-stRJN_4JyfLxtRoZD-Rzpjyz9nnuI_CMbkRkOoSk";

  static sasToken =
    "sv=2022-11-02&ss=b&srt=sco&sp=rwdlactfx&se=2030-07-05T19:59:33Z&st=2024-07-05T11:59:33Z&spr=https,http&sig=5GboONytsEL8EyTgy1htKc4lsUNbrSNQauJ%2B2Hi9VlE%3D";
  static accountName = "eqappdata";
  static FOLDER_MULTIMEDIA = "multimedia";
  static FOLDER_RESULT = "result";
  static FOLDER_NOTES = "notes";
  static FOLDER_EXTRA_NOTES = "textnote";
  static FOLDER_TASK = "task";
  static FOLDER_GENERAL_TASK = "generalTask";
  static FOLDER_OTHER = "other";

  static TYPE_IMAGE = 1;
  static TYPE_VIDEO = 2;
  static TYPE_YOUTUBE = 3;
  static TYPE_PDF = 4;

  static downloadZip = async (fileUrls) => {
    const zip = new JSZip();

    const fetchFile = async (url) => {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${url}`);
      }
      return response.blob();
    };

    const promises = fileUrls.map(async (item) => {
      const blob = await fetchFile(item.url);
      const fileName = item.name;
      zip.file(fileName, blob);
    });

    try {
      await Promise.all(promises);
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "files.zip");
    } catch (error) {
      console.error("Error creating zip file:", error);
    }
  };
  static downloadFilesOneByOne = async (images) => {
    const fetchImage = async (image) => {
      const response = await fetch(image.url);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${image.url}`);
      }
      const blob = await response.blob();
      return { blob, name: image.name }; // Extract the file name from the URL
    };

    try {
      const promises = images.map(fetchImage);
      const fetchedImages = await Promise.all(promises);

      fetchedImages.forEach(({ blob, name }) => {
        saveAs(blob, name);
      });
    } catch (error) {
      console.error("Error downloading images:", error);
    }
  };

  constructor() {
    // queue elements to be uploaded
    this.uploadQueue = [];
    this.isUploading = false;
    this.urisToShare = [];
    this.id = random(true) * 50;

    this.dropbox = new window.Dropbox.Dropbox({
      accessToken: MultimediaHelper.DropboxToken,
    });

    if (!MultimediaHelper.sasToken || !MultimediaHelper.accountName) {
      console.error("Azure Storage SAS token or account name not found");
      return;
    }

    this.blobServiceClient = new BlobServiceClient(
      `https://${MultimediaHelper.accountName}.blob.core.windows.net?${MultimediaHelper.sasToken}`
    );
  }

  handleCreateContainer = async (containerName) => {
    try {
      this.containerClient =
        this.blobServiceClient.getContainerClient(containerName);
      await this.containerClient.create();
      console.log(`Container "${containerName}" created successfully.`);
    } catch (error) {
      console.error(`Error creating container: ${error.message}`);
    }
  };

  listBlobsWithProperties = async (containerName) => {
    const containerClient =
      this.blobServiceClient.getContainerClient(containerName);

    let blobs = [];
    for await (const blob of containerClient.listBlobsFlat()) {
      const blockBlobClient = containerClient.getBlockBlobClient(blob.name);
      const properties = await blockBlobClient.getProperties();

      const url = `https://${MultimediaHelper.accountName}.blob.core.windows.net/${containerName}/${blob.name}?${MultimediaHelper.sasToken}`;
      blobs.push({
        name: blob.name,
        size: properties.contentLength,
        lastModifiedDate: properties.lastModified,
        url: url,
      });
    }

    return blobs;
  };

  listFilesInContainer = async (containerName) => {
    this.containerClient =
      this.blobServiceClient.getContainerClient(containerName);
    let blobItems = [];
    for await (const blob of this.containerClient.listBlobsFlat()) {
      if (blob.name.length > 1024) {
        throw new Error(
          `Blob name "${blob.name}" exceeds the maximum length of 1024 characters.`
        );
      }
      const blobUrl = this.containerClient.getBlobClient(blob.name).url;
      blobItems.push({ name: blob.name, url: blobUrl });
    }
    return blobItems;
  };

  /**
   * Function to remove an element from the queue upload queue.
   *
   * @param name The name of the file to be removed
   * @param target_origin The location.href of the file to be removed.
   */

  removeElementFromUploadQueue(name, target_origin) {
    this.uploadQueue = this.uploadQueue.filter(
      (el) => el.fileName != name || el.request_origin != target_origin
    );
  }

  /**
   * Function called when Android/IOS wrappers finish uploading one photo from the upload queueu
   * @param file_name the name of the file finished uploading
   * @param target_request the location.href of the upload item
   * @param dbxPath the path_display of the uplaod element, used to reconstruct the element from database
   */
  onUploadDoneCallback(file_name, target_request, dbxPath) {
    let x = this.uploadQueue.find(
      (el) => el.fileName == file_name && el.request_origin == target_request
    );
    if (!x) return;
    let dbxItem = { path_display: dbxPath, name: file_name };
    console.log("OK");
    dbxItem.type = MultimediaHelper.getTypeItem(dbxItem.fileName);

    const encodedFileName = encodeURIComponent(dbxItem.fileName);
    const url = `https://${MultimediaHelper.accountName}.blob.core.windows.net/${dbxItem.container}/${encodedFileName}?${MultimediaHelper.sasToken}`;

    dbxItem.path_display = dbxItem.fileName;
    dbxItem.fullUrl = url;
    dbxItem.thumbUrl = url;
    dbxItem.imageUrl = url;
    console.log(dbxItem);
    this.removeElementFromUploadQueue(file_name, target_request);
    this.finishCallback(target_request, file_name, dbxItem);
  }
  onUploadProgressCallback(file_name, percent, target_request) {
    let x = this.uploadQueue.find(
      (el) => el.fileName == file_name && el.request_origin == target_request
    );
    x.file.progress = percent;
    this.refreshUploadQueue();
  }

  /**
   * A function that adds elements
   * @description A callback function used by xamarin to add data to the uploadQueue.
   */

  async addDataToUploadQueue(fileName, imageUrl, isImage, target_origin) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    this.uploadQueue.push({
      request_origin: target_origin,
      path: this.path,
      fileName: fileName,
      file: {
        actual_image: new File([blob], fileName, { type: "image/png" }),
        fileName: fileName,
        imageUrl: imageUrl,
        progress: 0,
        type:
          isImage == "True"
            ? MultimediaHelper.TYPE_IMAGE
            : MultimediaHelper.TYPE_VIDEO,
      },
    });
    this.refreshUploadQueue();
    // if (this.isUploading) return;
    // this.isUploading = true;
    // this.startUploadQueueElements();
  }
  /**
   * Setter for the current multimedia session.
   */
  setCurrentSettings(
    type,
    horseId,
    userId,
    multimediaPage,
    noteOrTaskId,
    refreshUploadQueue
  ) {
    this.type = type;
    this.horseId = horseId;
    this.userId = userId;
    this.multimediaPage = multimediaPage;
    this.noteOrTaskId = noteOrTaskId;
    this.dropbox = new window.Dropbox.Dropbox({
      accessToken: MultimediaHelper.DropboxToken,
    });
    this.refreshUploadQueue = refreshUploadQueue;
    // queue elements to be uploaded
  }
  setRefreshCallback(callback) {
    this.refreshUploadQueue = callback;
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  mimeToExtension(mimeType) {
    const mimeMap = {
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/gif": "gif",
      "application/pdf": "pdf",
      // Add other mappings as needed
    };
    return mimeMap[mimeType] || ""; // Return empty string if not found
  }

  async shareItems(items, text) {
    try {
      const localFiles = [];

      for (const item of items) {
        const { url } = item;
        const response = await fetch(url);
        const blob = await response.blob();
        const mimeType = blob.type;

        const extension = mimeType.split("/")[1]; // Extract extension from MIME type
        const base64Data = await this.blobToBase64(blob);

        let fileName = item.name;
        if (!fileName.includes(".")) {
          fileName += `.${extension}`;
        }

        // Write the file to the Filesystem
        const savedFile = await Filesystem.writeFile({
          path: fileName,
          data: base64Data,
          directory: Directory.Cache,
        });

        localFiles.push(savedFile.uri);
      }

      await Share.share({
        title: "Share files",
        text: text || "",
        files: localFiles,
        dialogTitle: "Share with",
      });
    } catch (error) {
      console.error("Error sharing images:", error);
    }
  }

  /**
   * Setter for the callback function
   * @param {Function<>()} finishCallback The new finish callback function
   */
  setFinishCallback(finishCallback) {
    this.finishCallback = finishCallback;
  }

  /**
   * Setter for the progress callback function
   * @param {Function<>()} progressCallback The new progress callback function
   */
  setProgressCallback(progressCallback) {
    this.progressCallback = progressCallback;
  }

  /**
   * A function that adds files to the upload queue.
   *
   * @param {string} request_origin The location.href from which the files were added.
   * @param {Array<object>} files  List of files to be added to the upload queue.
   */
  addToUploadQueue(request_origin, files, static_path) {
    console.log(this.path);
    this.uploadQueue.push(
      ...files.map((file) => {
        return { file, request_origin, path: static_path ?? this.path };
      })
    );
    if (this.isUploading) return;
    this.isUploading = true;
    this.startUploadQueueElements();
  }

  /**
   * A function that takes all the elements from the queue, uploads them and calls the respective callbacks.
   */
  startUploadQueueElements = async () => {
    if (this.uploadQueue.length <= 0) {
      this.isUploading = false;
      return;
    }

    let el = { ...this.uploadQueue[0] };

    this._uploadWthSingleRequest(
      el.file,
      async (dbxItem) => {
        if (dbxItem == null) {
          this.finishCallback(el.file.fileName, null);
        } else {
          console.log(dbxItem.fileName);
          dbxItem.path_display = dbxItem.fileName;
          dbxItem.type = MultimediaHelper.getTypeItem(dbxItem);

          // const url = dbxItem.url;
          const encodedFileName = encodeURIComponent(dbxItem.fileName);
          const url = `https://${MultimediaHelper.accountName}.blob.core.windows.net/${dbxItem.container}/${encodedFileName}?${MultimediaHelper.sasToken}`;

          dbxItem.path_display = dbxItem.fileName;
          dbxItem.fullUrl = url;
          dbxItem.thumbUrl =
            MultimediaHelper.getTypeItem(dbxItem) ===
            MultimediaHelper.TYPE_VIDEO
              ? await this.generateThumbnail(url)
              : url;
          dbxItem.imageUrl = url;
          dbxItem.name = dbxItem.fileName;
          this.uploadQueue.shift();
          this.finishCallback(el.request_origin, dbxItem.fileName, dbxItem);
          this.startUploadQueueElements();
          // this.dropbox
          //   .filesGetThumbnail({
          //     path: dbxItem.path_display,
          //     size: "w480h320",
          //   })
          //   .then((response) => {
          //     let url = window.URL.createObjectURL(response.fileBlob);
          //     dbxItem.thumbUrl = url;

          //     this.uploadQueue.shift();
          //     this.finishCallback(el.request_origin, el.file.fileName, dbxItem);
          //     this.startUploadQueueElements();
          //   })
          //   .catch((error) => {
          //     dbxItem.thumbUrl = "";

          //     this.uploadQueue.shift();
          //     this.finishCallback(el.request_origin, el.file.fileName, dbxItem);
          //     this.startUploadQueueElements();
          //   });
        }
      },
      el.request_origin,
      el.path
    );
  };

  /**
   * A function that returns all the elements in the upload queue such that the origin or the items matches the origin of the requester.
   * @param {string} request_origin The location.href of the page that requested the upload list.
   *
   * @returns a list of items from upload list that match the requester's origin.
   */
  loadUploadQueue = (request_origin) => {
    return this.uploadQueue.filter((item) =>
      item.request_origin.startsWith(request_origin)
    );
  };

  getPath = (type) => {
    switch (type) {
      case MultimediaHelper.FOLDER_MULTIMEDIA:
        return `${MultimediaHelper.FOLDER_MULTIMEDIA}${this.horseId}`;
      case MultimediaHelper.FOLDER_EXTRA_NOTES:
        return `${this.type}_${this.noteOrTaskId}`;
      case MultimediaHelper.FOLDER_TASK:
        let horse = window.DataStorage.getAllHorses().find(
          (h) => h.id == this.horseId
        );
        return `${this.type}=${horse.userid}-${this.noteOrTaskId}`;

      case MultimediaHelper.FOLDER_GENERAL_TASK:
        return `${this.type}=${this.noteOrTaskId}`;

      default:
        return `${this.type}-${this.horseId}`;
    }
  };

  loadList = (request_origin, callback, type) => {
    this.loadCallback = callback;
    this.path = this.getPath(type).toLowerCase();
    this.handleCreateContainer(this.path)
      .then((response) => {
        if (this.type == MultimediaHelper.FOLDER_MULTIMEDIA) {
          this._getListFilesForMultimedia();
        } else if (
          this.type == MultimediaHelper.FOLDER_TASK &&
          this.multimediaPage
        ) {
          this._getListFilesForTasks();
        } else if (
          this.type == MultimediaHelper.FOLDER_NOTES &&
          this.multimediaPage
        ) {
          this._getListFilesForNotes();
        } else {
          this._getListFiles();
        }
      })
      .catch((error) => {
        if (this.type == MultimediaHelper.FOLDER_MULTIMEDIA) {
          this._getListFilesForMultimedia();
        } else if (
          this.type == MultimediaHelper.FOLDER_TASK &&
          this.multimediaPage
        ) {
          this._getListFilesForTasks();
        } else if (
          this.type == MultimediaHelper.FOLDER_NOTES &&
          this.multimediaPage
        ) {
          this._getListFilesForNotes();
        } else {
          this._getListFiles();
        }
      });
  };
  generateThumbnail(videoUrl, seekTime = 1) {
    return new Promise((resolve, reject) => {
      if (Capacitor.getPlatform() != "web") {
        FileChunkReader.generateThumbnail({
          videoUrl,
        }).then((result) => {
          resolve(result.thumbnail);
        });
        return;
      }

      const video = document.createElement("video");
      video.crossOrigin = "anonymous"; // Ensure the video is fetched with CORS headers

      video.src = videoUrl;
      video.preload = "metadata";
      video.currentTime = 0.001;

      video.addEventListener("seeked", () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");

        // Draw the video frame to the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Get the image data URL from the canvas
        const imageUrl = canvas.toDataURL("image/png");
        resolve(imageUrl);
      });

      video.addEventListener("error", (error) => {
        resolve(videoUrl);
        // reject(`Error loading video: ${error.message}`);
      });
    });
  }

  _getListFiles = () => {
    this.listFilesInContainer(this.path)
      .then(async (response) => {
        let result = [];
        for (let el of response) {
          // alert(JSON.stringify(el));
          el.path_display = el.name;
          el.fullUrl = el.url;
          el.thumbUrl =
            MultimediaHelper.getTypeItem(el) === MultimediaHelper.TYPE_VIDEO // Capacitor.getPlatform() === "web"
              ? await this.generateThumbnail(
                  // "http://localhost:8000/yt5s.io-Cargo%20-%20Ielele%20(Official%20Audio).mp4"
                  el.url
                )
              : el.url;
          el.imageUrl = el.url;
          el.type = MultimediaHelper.getTypeItem(el);
          result.push(el);
        }
        this.loadCallback(result);
        return;
        if (!response.entries) return;
        let items = [];
        for (let i = 0; i < response.entries.length; i++) {
          let item = response.entries[i];
          item.type = MultimediaHelper.getTypeItem(item);
          items.push(item);
        }
        items = items.sort(function (a, b) {
          if (a["server_modified"] > b["server_modified"]) return -1;
          if (a["server_modified"] < b["server_modified"]) return 1;
          return 0;
        });

        if (items.length != 0) {
          this._buildThumbs(items);
        } else {
          this.loadCallback(items);
        }
      })

      .catch((error) => {
        this.loadCallback(null);
      });
  };

  _getListFilesForMultimedia = () => {
    console.warn(this.path);
    this.listFilesInContainer({ path: this.path })
      .then((response) => {
        console.log(response);

        this.loadCallback(response);
        // }
        return;
        window.DataGate.getYoutubeMultimedia(
          this.userId,
          this.horseId,
          (code, youtubeData) => {
            if (!response.entries) return;
            let items = [];
            for (let i = 0; i < response.entries.length; i++) {
              let item = response.entries[i];
              item.type = MultimediaHelper.getTypeItem(item);
              //let ext = item.path_display.split('.').pop().toLowerCase();
              //if (ext == "png" || ext == "jpeg" || ext == "jpg" || ext == "gif" || ext == "bmp") {
              //    item.type = MultimediaHelper.TYPE_IMAGE;
              //} else {
              //    item.type = MultimediaHelper.TYPE_VIDEO;
              //}
              items.push(item);
            }
            items = items.sort(function (a, b) {
              if (a["server_modified"] > b["server_modified"]) return -1;
              if (a["server_modified"] < b["server_modified"]) return 1;
              return 0;
            });
            if (youtubeData != null) {
              for (let i = 0; i < youtubeData.length; i++) {
                let item = youtubeData[i];
                item.type = MultimediaHelper.TYPE_YOUTUBE;
                items.push(item);
              }
            }

            if (items.length != 0) {
              this._buildThumbs(items);
            } else {
              this.loadCallback(items);
            }
          }
        );
      })
      .catch((error) => {
        this.loadCallback(null);
      });
  };

  _getListFilesForTasks = () => {
    let end = new Date().formatDesh();
    let start = new Date().addDays(-92).formatDesh();
    window.db.getTasksByHorseIdForMultimedia(
      this.horseId,
      start,
      end,
      (taskArr) => {
        let horse = window.DataStorage.getAllHorses().find(
          (h) => h.id == this.horseId
        );
        let horseOwnerId = horse.userid;
        let items = [];
        if (!taskArr.length) this.loadCallback(items);
        for (let i = 0; i < taskArr.length; i++) {
          this.path = "Task" + horseOwnerId + "-" + taskArr[i]["Id"];
          this.dropbox
            .filesListFolder({ path: this.path })
            .then((response) => {
              if (!response.entries) return;

              for (let i = 0; i < response.entries.length; i++) {
                let item = response.entries[i];
                item.type = MultimediaHelper.getTypeItem(item);
                //let ext = item.path_display.split('.').pop().toLowerCase();
                //if (ext == "png" || ext == "jpeg" || ext == "jpg" || ext == "gif" || ext == "bmp") {
                //    item.type = MultimediaHelper.TYPE_IMAGE;
                //} else {
                //    item.type = MultimediaHelper.TYPE_VIDEO;
                //}
                items.push(item);
              }
              items = items.sort(function (a, b) {
                if (a["server_modified"] > b["server_modified"]) return -1;
                if (a["server_modified"] < b["server_modified"]) return 1;
                return 0;
              });

              if (i == taskArr.length - 1) {
                setTimeout(() => {
                  if (items.length != 0) {
                    this._buildThumbs(items);
                  } else {
                    this.loadCallback(items);
                  }
                }, 500);
              }
            })
            .catch((error) => {
              this.loadCallback(null);
            });
        }
      }
    );
  };

  _getListFilesForNotes = () => {
    window.db.getNotesByHorse(this.horseId, (notesArr) => {
      let items = [];
      this.dropbox
        .filesListFolder({ path: this.path })
        .then((response) => {
          if (!response.entries) return;
          for (let i = 0; i < response.entries.length; i++) {
            let item = response.entries[i];
            item.type = MultimediaHelper.getTypeItem(item);
            //let ext = item.path_display.split('.').pop().toLowerCase();
            //if (ext == "png" || ext == "jpeg" || ext == "jpg" || ext == "gif" || ext == "bmp") {
            //    item.type = MultimediaHelper.TYPE_IMAGE;
            //} else {
            //    item.type = MultimediaHelper.TYPE_VIDEO;
            //}
            items.push(item);
          }
          items = items.sort(function (a, b) {
            if (a["server_modified"] > b["server_modified"]) return -1;
            if (a["server_modified"] < b["server_modified"]) return 1;
            return 0;
          });
          if (!notesArr.length) this.loadCallback(items);
          for (let i = 0; i < notesArr.length; i++) {
            this.path =
              `${MultimediaHelper.FOLDER_EXTRA_NOTES}-` +
              this.horseId +
              "-" +
              notesArr[i]["Id"];
            this.dropbox
              .filesListFolder({ path: this.path })
              .then((response) => {
                if (!response.entries) return;

                for (let i = 0; i < response.entries.length; i++) {
                  let item = response.entries[i];
                  item.type = MultimediaHelper.getTypeItem(item);
                  //let ext = item.path_display.split('.').pop().toLowerCase();
                  //if (ext == "png" || ext == "jpeg" || ext == "jpg" || ext == "gif" || ext == "bmp") {
                  //    item.type = MultimediaHelper.TYPE_IMAGE;
                  //} else {
                  //    item.type = MultimediaHelper.TYPE_VIDEO;
                  //}
                  items.push(item);
                }
                items = items.sort(function (a, b) {
                  if (a["server_modified"] > b["server_modified"]) return -1;
                  if (a["server_modified"] < b["server_modified"]) return 1;
                  return 0;
                });

                if (i == notesArr.length - 1) {
                  setTimeout(() => {
                    if (items.length != 0) {
                      this._buildThumbs(items);
                    } else {
                      this.loadCallback(items);
                    }
                  }, 500);
                }
              })
              .catch((error) => {
                this.loadCallback(null);
              });
          }
        })
        .catch((error) => {
          this.loadCallback(null);
        });
    });
  };

  _checkAllThumbLoaded = () => {
    let notLoadedItems = this.items.filter((item) => item.thumbUrl == null);
    if (notLoadedItems.length == 0) {
      this.loadCallback(this.items);
    }
  };

  _buildThumbs = (items) => {
    this.items = items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type == MultimediaHelper.TYPE_YOUTUBE) {
        let code = this._getYoutubeCode(items[i].Link);
        items[i].thumbUrl = `https://img.youtube.com/vi/${code}/hqdefault.jpg`;
        items[i].fullUrl = `https://www.youtube.com/embed/${code}`;
      } else {
        this._loadThumb(items[i]);
      }
    }
    this._checkAllThumbLoaded();
  };

  _getYoutubeCode = (link) => {
    //https://youtu.be/tezlfdvAzcc
    //https://www.youtube.com/watch?v=M_hVFRTzFw8

    if (link.indexOf("youtu.be") != -1) {
      let code = link.split("/").pop();
      return code;
    }
    if (link.indexOf("?v=") != -1) {
      let code = link.split("?v=").pop();
      return code;
    }
    return null;
  };

  _loadThumb = (item) => {
    new Promise((resolve, reject) => {
      this.dropbox
        .filesGetThumbnail({
          path: item.path_display,
          size: "w480h320",
        })
        .then((response) => {
          let url = window.URL.createObjectURL(response.fileBlob);
          item.thumbUrl = url;
          this._checkAllThumbLoaded();
        })
        .catch((error) => {
          item.thumbUrl = "";
          this._checkAllThumbLoaded();
        });
    });
  };

  getItemLink = (item, callback) => {
    if (item.fullUrl != null) {
      if (callback) callback(item.fullUrl);
      return;
    }
    if (item.type == MultimediaHelper.TYPE_YOUTUBE) {
      let code = this._getYoutubeCode(item);
      item.fullUrl = `https://www.youtube.com/embed/${code}`;
      if (callback) callback(item.fullUrl);
      return;
    }

    this.dropbox
      .filesGetTemporaryLink({ path: item.path_display })
      .then((response) => {
        item.fullUrl = response.link;
        if (callback) callback(item.fullUrl);
      })
      .catch(function (error) {
        if (callback) callback(null);
      });
  };

  _loadThubmAndUploadCallback = (
    fileName,
    dbxItem,
    callback,
    request_origin
  ) => {
    if (dbxItem == null) {
      callback(fileName, null);
    } else {
      dbxItem.type = MultimediaHelper.getTypeItem(dbxItem);
      //let ext = dbxItem.path_display.split('.').pop().toLowerCase();
      //if (ext == "png" || ext == "jpeg" || ext == "jpg" || ext == "gif" || ext == "bmp") {
      //    dbxItem.type = MultimediaHelper.TYPE_IMAGE;
      //} else {
      //    dbxItem.type = MultimediaHelper.TYPE_VIDEO;
      //}
      this.dropbox
        .filesGetThumbnail({
          path: dbxItem.path_display,
          size: "w480h320",
        })
        .then((response) => {
          let url = window.URL.createObjectURL(response.fileBlob);
          dbxItem.thumbUrl = url;

          callback(fileName, dbxItem, request_origin);
        })
        .catch((error) => {
          dbxItem.thumbUrl = "";
          callback(fileName, dbxItem, request_origin);
        });
    }
  };
  wait(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  _setSoothProgress = async (file, new_progress, request_origin) => {
    const duration = 2000;
    const steps_per_seconds = 2;
    const steps = (duration / 1000) * steps_per_seconds;
    const delay_between_steps = 1 / steps_per_seconds;
    for (let i = 0; i <= steps; i++) {
      // alert(file.progress + ((new_progress - file.progress) * i) / steps);
      // this.progressCallback(
      //   request_origin,
      //   file.name,
      //   file.progress + ((file.progress - new_progress) * i) / steps
      // );
    }
  };

  _uploadWthSingleRequest = async (
    file,
    doneCallback,
    request_origin,
    file_path
  ) => {
    const path = file_path + "/" + file.fileName;
    const maxBlob = 4 * 1024 * 1024;
    if (true) {
      this.uploadFileWithProgress(
        file,
        path,
        doneCallback,
        request_origin,
        this.dropbox
      );
      return;
    }

    if (file.actual_image) {
      console.log(file);

      if (Capacitor.getPlatform() == "web") {
        FileChunkReader.azureBlobStorageUploadFile({
          file: file.actual_image,
          sasToken: MultimediaHelper.sasToken,
          accountName: MultimediaHelper.accountName,
          containerName: this.containerClient.containerName,
          targetPath: file.fileName,
          progressCallback: (progress) => {},
          doneCallback: (success, response) => {
            if (success) {
              console.log(response);
              doneCallback(response, request_origin);
            } else {
              console.error("Upload failed");
            }
          },
        }).then();
      } else {
        // const doneCallback = doneCallback;
        FileChunkReader.addListener("uploadComplete", (info) => {
          FileChunkReader.removeAllListeners("uploadComplete");
          // alert("DONE");
          if (info.success) {
            if (typeof info === "string") {
              doneCallback(JSON.parse(info), request_origin);
            } else {
              doneCallback(info, request_origin);
            }
          } else {
            console.error("Upload failed");
          }
        });
        FileChunkReader.azureBlobStorageUploadFile({
          uri: file.media_path,
          sasToken: MultimediaHelper.sasToken,
          accountName: MultimediaHelper.accountName,
          containerName: this.containerClient.containerName,
          blobName: file.fileName,
        });
      }
    } else {
      FileChunkReader.addListener("uploadProgress", (info) => {
        this.progressCallback(request_origin, file.fileName, info.progress);
      });

      FileChunkReader.addListener("uploadComplete", (info) => {
        FileChunkReader.removeAllListeners("uploadProgress");
        FileChunkReader.removeAllListeners("uploadComplete");
        if (info.success) {
          if (typeof info === "string") {
            doneCallback(JSON.parse(info), request_origin);
          } else {
            doneCallback(info, request_origin);
          }
        } else {
          console.error("Upload failed");
        }
      });
      FileChunkReader.azureBlobStorageUploadFile({
        uri: file.media_path,
        sasToken: MultimediaHelper.sasToken,
        accountName: MultimediaHelper.accountName,
        containerName: this.containerClient.containerName,
        blobName: file.fileName,
        targetPath: path,
      }).then();
    }
  };
  base64ToBlob(base64, mimeType = "") {
    // Decode Base64 string to a binary string
    const binaryStr = atob(base64);
    // Create a Uint8Array of the same length as the binary string
    const length = binaryStr.length;
    const bytes = new Uint8Array(length);
    // Fill the Uint8Array with byte values from the binary string
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryStr.charCodeAt(i);
    }
    // Create and return a Blob from the Uint8Array
    return new Blob([bytes], { type: mimeType });
  }
  async uploadFileChunk(options) {
    const {
      sasToken,
      accountName,
      containerName,
      file,
      targetPath,
      progressCallback,
      doneCallback,
    } = options;

    const blobServiceClient = new BlobServiceClient(
      `https://${accountName}.blob.core.windows.net?${sasToken}`
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(targetPath);

    const chunkSize = 4 * 1024 * 1024; // 4MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    let uploadedBytes = 0;
    const blockList = [];

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      const arrayBuffer = await chunk.arrayBuffer();
      const buffer = new Uint8Array(arrayBuffer);
      const blockId = btoa(String(i).padStart(5, "0")); // Base64 encode the block ID
      console.log("STAGE BLOCK");
      await blockBlobClient.stageBlock(blockId, buffer, buffer.length);
      uploadedBytes += chunkSize;
      progressCallback(Math.min((uploadedBytes / file.size) * 100, 100));
      console.log("STAGE BLOCK DONE");

      blockList.push(blockId);
    }

    await blockBlobClient.commitBlockList(blockList, {
      blobHTTPHeaders: {
        blobContentType: file.type,
      },
    });

    doneCallback();
  }
  uploadFileWithProgress = async (
    file,
    path,
    doneCallback,
    request_origin,
    dbx
  ) => {
    const fileSize = file.fileSize;

    try {
      if (file.actual_image && Capacitor.getPlatform() == "web") {
        FileChunkReader.azureBlobStorageUploadFileChunk({
          file: file.actual_image,
          sasToken: MultimediaHelper.sasToken,
          accountName: MultimediaHelper.accountName,
          containerName: this.containerClient.containerName,
          targetPath: file.fileName,
          progressCallback: (progress) => {
            if (this.progressCallback)
              this.progressCallback(
                request_origin,
                file.fileName,
                progress / 100
              );
          },
          doneCallback: (success, response) => {
            console.log(response);
            if (success) {
              console.log(response);
              doneCallback(response, request_origin);
            } else {
              console.error("Upload failed");
            }
          },
        }).then();
      } else {
        FileChunkReader.addListener("uploadProgress", (info) => {
          this.progressCallback(request_origin, file.fileName, info.progress);
        });

        FileChunkReader.addListener("uploadComplete", (info) => {
          FileChunkReader.removeAllListeners("uploadProgress");
          FileChunkReader.removeAllListeners("uploadComplete");

          if (info.success) {
            if (typeof info === "string") {
              doneCallback(JSON.parse(info), request_origin);
            } else {
              doneCallback(info, request_origin);
            }
          } else {
            console.error("Upload failed");
          }
        });
        FileChunkReader.azureBlobStorageUploadFileChunk({
          uri: file.media_path,
          sasToken: MultimediaHelper.sasToken,
          accountName: MultimediaHelper.accountName,
          containerName: this.containerClient.containerName,
          blobName: file.fileName,
          fileSize: fileSize,
        }).then();
      }
    } catch (error) {
      doneCallback(null, request_origin);
    }
  };

  cancelUploading = (fileName) => {
    let cancelIndex = this.uploadQueue.findIndex(
      (f) => f.file.fileName == fileName
    );
    this.uploadQueue.splice(cancelIndex, 1);

    this.refreshUploadQueue();
  };

  uploadYoutube = (link, callback) => {
    window.DataGate.addYoutubeMultimedia(
      link,
      this.userId,
      this.horseId,
      (c, data) => {
        let code = this._getYoutubeCode(link);
        const item = {
          Link: link,
          type: MultimediaHelper.TYPE_YOUTUBE,
          fullUrl: `https://www.youtube.com/embed/${code}`,
          thumbUrl: `https://img.youtube.com/vi/${code}/hqdefault.jpg`,
        };
        callback(item);
      }
    );
  };

  deleteItems = (items, callback) => {
    this.deleteCallback = callback;
    this.itemsForDelete = items;
    for (let i = 0; i < this.itemsForDelete.length; i++) {
      this._deleteItem(this.itemsForDelete[i]);
    }
  };

  _deleteItem = (item) => {
    if (item.type == MultimediaHelper.TYPE_YOUTUBE) {
      window.DataGate.deleteYoutubeMultimedia(item.Id, () => {
        item.isDeleted = true;
        this._checkItemsDelete();
      });
    } else {
      const blobClient = this.containerClient.getBlobClient(item.name);

      // Delete the blob
      blobClient.delete().then(() => {
        item.isDeleted = true;
        this._checkItemsDelete();
      });
      // this.dropbox
      //   .filesDelete({ path: item.path_display })
      //   .then((response) => {
      //     item.isDeleted = true;
      //     this._checkItemsDelete();
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     item.isDeleted = true;
      //     this._checkItemsDelete();
      //   });
    }
  };

  _checkItemsDelete = () => {
    let notDeletedItems = this.itemsForDelete.filter((item) => !item.isDeleted);
    if (notDeletedItems.length == 0) {
      this.deleteCallback();
    }
  };

  changeName = async (oldFileName, newFileName, callback) => {
    const oldBlobClient = this.containerClient.getBlobClient(oldFileName);
    const newBlobClient = this.containerClient.getBlobClient(newFileName);

    // Copy the blob to the new name
    newBlobClient.beginCopyFromURL(oldBlobClient.url).then(() =>
      oldBlobClient
        .delete()
        .then(() => callback(true))
        .catch(() => callback(false))
    );

    // const pathNew =
    //   item.path_display.substring(0, item.path_display.lastIndexOf("/")) +
    //   "/" +
    //   nameNew;
    // this.dropbox
    //   .filesMove({
    //     from_path: item.path_display,
    //     to_path: pathNew,
    //   })
    //   .then((response) => {
    //     response.thumbUrl = item.thumbUrl;
    //     response.type = item.type;
    //     if (callback) callback(response);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //   });
  };

  downloadItems = (items, callback) => {
    let dowloadStep = (itemIndex) => {
      if (itemIndex < items.length) {
        this._downloadItem(items[itemIndex], () => {
          dowloadStep(itemIndex + 1);
        });
      } else {
        window.Spinner.hide();
        //end
      }
    };

    window.Spinner.show();
    dowloadStep(0);
  };

  /**
   *
   * Download a list of items from dropbox
   *
   * @param {String[]} items list of items to be downloaded
   *
   * @returns A list of blobs, corresponding to the items provided.
   */
  downloadAllItems = async (items) => {
    let result = [];
    for (let item of items) {
      if (item.type == MultimediaHelper.TYPE_YOUTUBE) {
        continue;
      }
      const response = await this.dropbox.filesDownload({
        path: item.path_display,
      });
      result.push(
        new File([response.fileBlob], response.name, {
          type: "image/jpeg",
        })
      );
    }
    return result;
  };

  _downloadItem = (item, callback) => {
    if (item.type == MultimediaHelper.TYPE_YOUTUBE) {
      callback();
      return;
    }

    this.dropbox
      .filesDownload({
        path: item.path_display,
      })
      .then((response) => {
        var downloadUrl = URL.createObjectURL(response.fileBlob);
        var downloadButton = document.createElement("a");
        downloadButton.setAttribute("href", downloadUrl);
        downloadButton.setAttribute("download", response.name);
        downloadButton.setAttribute("class", "button");
        downloadButton.innerText = "Download: " + response.name;
        downloadButton.click();

        callback();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  showMobileFullscreen(imageItem) {
    if (
      window.multimediaMobileHelper.isActive &&
      this.type == MultimediaHelper.FOLDER_MULTIMEDIA
    ) {
      window.multimediaMobileHelper.showFullScreen(
        this.userId,
        this.horseId,
        imageItem
      );
      return true;
    }
    return false;
  }

  downloadAndShareItems(items) {
    if (window.multimediaMobileHelper.isActive) {
      window.multimediaMobileHelper.downloadAndShareItems(items);
    }
  }

  hasImages = (callback) => {
    this.path = `${MultimediaHelper.FOLDER_MULTIMEDIA}=${this.horseId}`;
    this.dropbox
      .filesListFolder({ path: this.path })
      .then((response) => {
        if (callback) callback(response.entries.length > 0);
      })
      .catch((error) => {
        if (callback) callback(false);
      });
  };

  static getTypeItem(item) {
    let ext = item.path_display.split(".").pop().toLowerCase();
    if (
      ext == "png" ||
      ext == "jpeg" ||
      ext == "jpg" ||
      ext == "gif" ||
      ext == "bmp"
    ) {
      return MultimediaHelper.TYPE_IMAGE;
    } else if (ext == "pdf") {
      return MultimediaHelper.TYPE_PDF;
    } else {
      return MultimediaHelper.TYPE_VIDEO;
    }
  }
}
