import React from "react";
import "./Modal.css";
export default function Modal({
  title,
  body,
  button1_text,
  button2_text,
  button1_callback,
  button2_callback,
}) {
  return (
    <div className="modal--holder">
      <div className="modal">
        <h1 className="modal__heading">{title}</h1>
        <p className="modal__text">{body}</p>
        <div className="modal__buttons">
          <button
            className="modal__button modal__button--secondary"
            onClick={button1_callback}
          >
            {button1_text}
          </button>
          {button2_text && (
            <button
              className="modal__button modal__button--primary"
              onClick={button2_callback}
            >
              {button2_text}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
