import React, { Component, useState } from "react";
import { useEffect } from "react";
import "./SendSalesData.css";
import ReactDOM from "react-dom";
import BasicButton from "../Controls/Button/BasicButton";
import PaperPlaneIcon from "../Icons/PaperPlane";
import ToggleView from "../Controls/ToggleView/ToggleView";
import HorseGallery from "../Multimedia/HorseGallery";
import MultimediaHelper from "../Multimedia/MultimediaHelper";
import Modal from "../Modal/Modal";
import Notification from "../Notification/Notification";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";
function SendSalesData({
  horse,
  display_gallery,
  display_notes,
  display_results,
  presentationMode,
  pedigreeText,
}) {
  const getGender = (index) => {
    var genderText = "";
    if (index == 0) {
      genderText = "";
    } else if (index == 1) {
      genderText = window.lang.horseTypeMare;
    } else if (index == 2) {
      genderText = window.lang.horseTypeGelding;
    } else {
      genderText = window.lang.horseTypeStallion;
    }
    return genderText;
  };
  const horseLogo =
    horse.LogoFile != null && horse.LogoFile != ""
      ? window.DataGate.baseurl + horse.LogoFile
      : "img/horselogo.png";

  const [logoFile, setLogoFile] = useState("img/horselogo.png");
  const [name, setName] = useState();
  useEffect(() => {
    if (!horse) {
      return;
    }

    if (horse.LogoFile != null)
      setLogoFile(window.DataGate.baseurl + horse.LogoFile);
    setName(horse.name);
  }, [horse]);

  const [sendData, setSendData] = useState(true);
  const [sendLink, setSendLink] = useState(true);
  const [shareItems, setShareItems] = useState(Array(3).fill(true));

  const [galleryPhotos, setGalleryPhotos] = useState([]);
  const [resultPhotos, setResultPhotos] = useState([]);
  const [notesPhotos, setNotesPhotos] = useState([]);

  const [showLinkModal, setShowLinkModal] = useState(false);
  const [presentationId, setPresentationId] = useState("");

  const [showNotification, setShowNotification] = useState(false);

  const helper = new MultimediaHelper();

  const createPresentationLink = () => {
    window.DataGate.insertHorsePresentation(
      {
        user_id: "",
        horse_id: horse.id,
        display_gallery: shareItems[0],
        display_results: shareItems[1],
        display_notes: shareItems[2],
      },
      (code, data) => {
        if (code != 1) {
          alert("Something went wrong");
          return;
        }
        if (Capacitor.getPlatform() === "web") {
          setPresentationId(data);
          setShowLinkModal(true);
          window.Spinner.hide();
        } else {
          const shareData = {
            title: `Sales data for ${horse.name}`,
            text: "",
            url: getFullLink(data),
          };
          if (!navigator.canShare(shareData)) {
            window.Spinner.hide();
            alert("Can't share data.");
            return;
          }
          navigator.share(shareData).then(() => {
            window.Spinner.hide();
          });
        }
      }
    );
  };

  const sendSalesData = async () => {
    window.Spinner.show();
    let images_to_share = [];

    if (shareItems[0]) images_to_share.push(...galleryPhotos);
    if (shareItems[1]) images_to_share.push(...resultPhotos);
    if (shareItems[2]) images_to_share.push(...notesPhotos);

    if (sendData) {
      if (images_to_share.length <= 30) {
        if (Capacitor.getPlatform() === "web") {
          createPresentationLink();
          return;
        }

        if (images_to_share.length == 0) return;
        if (sendLink) {
          window.DataGate.insertHorsePresentation(
            {
              user_id: "",
              horse_id: horse.id,
              display_gallery: shareItems[0],
              display_results: shareItems[1],
              display_notes: shareItems[2],
            },
            (code, data) => {
              if (code != 1) {
                alert("Something went wrong");
                window.Sprinner.hide();
                return;
              }
              helper.shareItems(images_to_share, getFullLink(data)).then(() => {
                window.Spinner.hide();
              });
            }
          );
        } else {
          helper.shareItems(images_to_share).then(() => {
            window.Spinner.hide();
          });
        }

        return;
      } else {
        if (sendLink) createPresentationLink();
      }
      return;
    }

    if (sendLink) {
      createPresentationLink();
    }
  };

  const clickToggle = (index) => {
    let aux_state = [...shareItems];
    aux_state[index] = !aux_state[index];
    setShareItems(aux_state);
  };

  const drawCustomHeader = (title, index) => {
    if (presentationMode) {
      return (
        <div className="hgHeader">
          <div style={{ float: "left" }}>{title}</div>
        </div>
      );
    }

    return (
      <div className="hgHeader">
        <div style={{ float: "left" }}>{title}</div>
        <ToggleView
          name={title}
          checked={shareItems[index]}
          onClick={() => clickToggle(index)}
        />
      </div>
    );
  };

  const getFullLink = (presId) => {
    const currentPresentationId = presId || presentationId;
    // const url = "http://localhost:3000";
    const url = "https://eqeapp.com/eqapp/index.aspx";
    // const url = "https://eqapp-test.azurewebsites.net/eqapp/index.aspx";
    return url + "#publicPresentationDataWindow?id=" + currentPresentationId;
  };

  const renderLinkModalBody = () => {
    return (
      <>
        <span>{getFullLink()}</span>
        <svg
          onClick={() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5500);
            navigator.clipboard.writeText(getFullLink());
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          style={{ width: 20, paddingTop: 5 }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
          />
        </svg>
      </>
    );
  };
  return (
    <div className="sales-data--container">
      <div className="sales-data--holder">
        <div className="sales-data--profile--holder">
          <div className="sales-data--profile">
            <img
              className="hvmLogo sales-data--profile-image"
              style={{ backgroundImage: `url("${horseLogo}")` }}
            />

            <div className="sales-data--title-holder">
              <span className="sales-data--title-name">{horse.name}</span>

              {pedigreeText && pedigreeText.trim().length > 1 && (
                <span className="sales-data--title-born-year">
                  {pedigreeText}
                </span>
              )}
              <span className="sales-data--title-gender">
                {getGender(horse.Gender)}
              </span>

              <span className="sales-data--title-born-year">
                {horse.BirthYear}
              </span>
            </div>
            <div className="sales-data--filler"></div>
          </div>
          {!presentationMode && (
            <div className="sales-data--action-buttons">
              <BasicButton
                text="Send Sales Data"
                svgIcon={<PaperPlaneIcon />}
                onClick={sendSalesData}
              />
              <div class="sales-data--toggle-holder">
                <ToggleView
                  name="sendData"
                  checked={sendData}
                  onClick={() => setSendData(!sendData)}
                />
                <span>Send data if possible</span>
              </div>
              <div class="sales-data--toggle-holder">
                <ToggleView
                  name="sendLink"
                  checked={sendLink}
                  onClick={() => setSendLink(!sendLink)}
                />
                <span>Send link to presentation</span>
              </div>
            </div>
          )}
        </div>
        <div className="sales-data--gallery">
          {(!presentationMode || display_gallery) && (
            <HorseGallery
              hideEditImages={presentationMode}
              showYoutube={false}
              showUploadButton={false}
              type={"Multimedia"}
              horse={horse}
              title={"Galleri"}
              customHeader={drawCustomHeader("Galleri", 0)}
              isConcurrent={true}
              setAuxiliarImageList={setGalleryPhotos}
            />
          )}
          {(!presentationMode || display_results) && (
            <HorseGallery
              hideEditImages={presentationMode}
              showUploadButton={false}
              type={"Result"}
              horse={horse}
              title={"Result"}
              acceptPdf={false}
              customHeader={drawCustomHeader("Result", 1)}
              isConcurrent={true}
              setAuxiliarImageList={setResultPhotos}
            />
          )}
          {(!presentationMode || display_notes) && (
            <HorseGallery
              hideEditImages={presentationMode}
              showUploadButton={false}
              type={"Notes"}
              horse={horse}
              title={"Notes"}
              customHeader={drawCustomHeader("Notes", 2)}
              isConcurrent={true}
              setAuxiliarImageList={setNotesPhotos}
            />
          )}
        </div>
      </div>
      {showLinkModal && (
        <Modal
          title="Presentation link"
          body={renderLinkModalBody()}
          button1_text={"Share link"}
          button2_text={"Cancel"}
          button2_callback={() => setShowLinkModal(false)}
          button1_callback={() => {
            const shareData = {
              title: `Sales data for ${horse.name}`,
              text: "",
              url: getFullLink(),
            };
            if (!navigator.canShare(shareData)) {
              alert("Can't share data.");
              return;
            }
            navigator.share(shareData);
          }}
        />
      )}
      {showNotification && <Notification text={"Copied to clipboard"} />}
    </div>
  );
}

export default class SendSalesDataHolder {
  createSendSalesData(horse) {
    window.db.getPedigreeByHorse(horse.id, (pedigreeArr) => {
      let pedigreeText = "";
      if (pedigreeArr.length != 0) {
        const pedigreeData = JSON.parse(pedigreeArr[0]["PedigreeJsonText"]);
        try {
          pedigreeText =
            pedigreeData.mather + " / " + pedigreeData.grmather_father;
        } catch (err) {}
      }
      ReactDOM.render(
        <SendSalesData pedigreeText={pedigreeText} horse={horse} />,
        document.getElementById("sendSalesDataPageReact")
      );
    });

    ReactDOM.render(
      <SendSalesData pedigreeText={""} horse={horse} />,
      document.getElementById("sendSalesDataPageReact")
    );
  }
}

function NoHorseFoundView() {
  return (
    <div className="empty--holder">
      <div className="empty--info">
        <div className="empty--illustration">
          <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M63 63L52.5003 52.5M60 34.5C60 48.5833 48.5833 60 34.5 60C20.4167 60 9 48.5833 9 34.5C9 20.4167 20.4167 9 34.5 9C48.5833 9 60 20.4167 60 34.5Z"
              stroke="#737373"
              strokeWidth="6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="empty--title">
          We couldn’t find what you are looking for
        </div>
      </div>
    </div>
  );
}

export class PublicHorsePresentationHolder {
  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  async createPublicPresentationData() {
    const link_id = this.getParameterByName("id");

    const data = await fetch(
      window.DataGate.horsePresentationUrl +
        "/GetHorsePresentation?link_id=" +
        link_id
    ).then((r) => r.json());

    if (!data.DataObject.id) {
      ReactDOM.render(
        <NoHorseFoundView />,
        document.getElementById("publicPresentationPageReact")
      );
      return;
    }
    let horse = data.DataObject.horse_item;
    horse.name = horse.Name;
    horse.userid = data.DataObject.user_id;
    horse.id = horse.Id;
    horse.LogoFile = data.DataObject.logo_file;

    let pedigreeText = "";
    if (data.DataObject.pedigree_json) {
      let pedigreeData = JSON.parse(data.DataObject.pedigree_json);
      try {
        pedigreeText =
          pedigreeData.mather + " / " + pedigreeData.grmather_father;
      } catch (err) {}
    }
    ReactDOM.render(
      <SendSalesData
        presentationMode={true}
        display_gallery={data.DataObject.display_gallery}
        display_results={data.DataObject.display_results}
        display_notes={data.DataObject.display_notes}
        horse={data.DataObject.horse_item}
        pedigreeText={pedigreeText}
      />,
      document.getElementById("publicPresentationPageReact")
    );
  }
}
