import React, { Component } from "react";
import "./RaiderAndOtherTasks.css";
import Modal from "../../Modal/Modal";
import RiderView from "../RiderView/RiderView";
import OtherTasksView from "../../OtherTasksView/OtherTasksView";

export default function RaiderAndOtherTasks({
  filteredTrainings,
  filteredHorses,
  date,
  showOnlyOwnHorses,
  generalTaskArr,
}) {
  return (
    <div className="raiderAndOtherTasksWrapper">
      <div className="raiderAndOtherTasksContainer">
        <div className="raiderAndOtherTasksRaider">
          <RiderView
            filteredHorses={filteredHorses}
            filteredTrainings={filteredTrainings}
            date={date}
            showOnlyOwnHorses={showOnlyOwnHorses}
          />
        </div>
        <div className="raiderAndOtherTasksOtherTasks">
          <OtherTasksView date={date} generalTaskArr={generalTaskArr} />
        </div>
      </div>
    </div>
  );
}
